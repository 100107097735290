<template>
    <div class="ModalTable">
        <h3>{{ itemData.title }}</h3>
        <div class="table-entity">
            <div class="table-shell" v-if="!notYet">
                <a-table
                    class="full-table"
                    :loading="loading"
                    :columns="itemData.columns"
                    size="small"
                    :row-key="
                        (r, i) => {
                            i.toString();
                        }
                    "
                    :data-source="data"
                    :pagination="false"
                >
                </a-table>
            </div>
            <div style="display: flex; padding: 10px 20px" v-if="!notYet">
                <div style="flex: 1"></div>
                <a-pagination
                    :page-size="pageSize"
                    v-model="current"
                    :total="total"
                    @change="onChange"
                />
            </div>
            <div class="not-yet" v-if="notYet">暂无数据</div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

import { http } from "../../utils/Http.js";

export default {
    props: {
        itemData: Object,
    },

    data() {
        return {
            data: [],
            newDate: dayjs(new Date()).format("YYYY-MM-DD"), //统计时间
            loading: false,

            //暂无数据
            notYet: false,

            //分页
            current: 1,
            pageSize: 13,
            total: 0,

            url: null,
            params: null,
        };
    },

    watch: {
        itemData(value) {
            console.log(value);
            this.getParams(value);
            this.data = [];
        },
    },

    created() {
        this.getParams(this.itemData);
    },

    methods: {
        //分页
        onChange(page, pageSize) {
            this.current = page;
            console.log(this.itemData);
            if (this.itemData.type == 4) {
                this.params.start = (this.current - 1) * this.pageSize;
                this.params.limit = this.pageSize;
            } else {
                this.params.PAGESIZE = this.pageSize;
                this.params.PAGENUMBER = this.current;
            }

            // console.log(this.params);
            this.getData(this.url, this.params);
        },

        getParams(item) {
            var me = this;

            var url = "";
            var params = [];

            if (item.type == 1) {
                url = "/data/q30/HJJC_DAYTOTAL_NEW_ABNORMAL_LIST.aspx";
                params = {
                    CKM: me.$store.state.loggedIn.CKM,
                    TOTALDAY: me.newDate,
                    BJLX: 1,
                    PAGESIZE: me.pageSize,
                    PAGENUMBER: me.current,
                };
            } else if (item.type == 2) {
                url = "/data/q30/HJJC_DAYTOTAL_NEW_ABNORMAL_LIST.aspx";
                params = {
                    CKM: me.$store.state.loggedIn.CKM,
                    TOTALDAY: me.newDate,
                    BJLX: 2,
                    PAGESIZE: me.pageSize,
                    PAGENUMBER: me.current,
                };
            } else if (item.type == 3) {
                url = "/data/q30/HJJC_DAYTOTAL_NEW_LACK_LIST.aspx";
                params = {
                    CKM: me.$store.state.loggedIn.CKM,
                    TOTALDAY: me.newDate,
                    PAGESIZE: me.pageSize,
                    PAGENUMBER: me.current,
                };
            } else if (item.type == 4) {
                url = "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx";
                params = {
                    CKM: me.$store.state.loggedIn.CKM,
                    SFBH: "",
                    SBH: "",
                    XBH: "",
                    JZMC: "",
                    SXZM: "",
                    KSSJ: me.newDate,
                    JSSJ: me.newDate,
                    start: (me.current - 1) * me.pageSize,
                    limit: me.pageSize,
                };
            }

            this.url = url;
            this.params = params;

            this.getData(url, params);
        },

        getData(url, params) {
            var me = this;
            this.loading = true;
            // console.log(me.$store.state.loggedIn);
            http({
                url: url,
                data: params,
                success(obj) {
                    console.log(obj);

                    me.loading = false;

                    me.data = obj.Data;

                    if (obj.Data.length > 0) {
                        me.notYet = false;
                    } else {
                        me.notYet = true;
                    }

                    if (obj.Data1 && obj.Data1.length > 0) {
                        me.total = obj.Data1[0].ROWSCOUNT;
                    }
                },
                failure(obj) {
                    me.loading = false;
                },
                me,
            });
        },
    },
};
</script>
<style lang="scss">
.ModalTable {
    table tbody tr:hover > td {
        background-color: #ffffff00 !important;
    }
}
</style>

<style lang="scss" scoped>
.ModalTable {
    height: 633px;

    // table tbody tr:hover>td {
    // 	background-color:red !important
    // }

    h3 {
        font-size: 16px;
        height: 54px;
        line-height: 22px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #09c8ff;
        padding: 20px 0 12px 0;
    }

    .table-entity {
        // .full-table{
        // 	.ant-table{
        // 		.ant-table-tbody{
        // 			tr:hover > td {
        // 				background-color:#ffffff00 !important
        // 			}
        // 		}
        // 	}
        // }

        .table-shell {
            height: 518px;
            overflow: hidden;
        }

        &::v-deep .full-table {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            border: 0;

            .ant-table {
                border: 0;

                .ant-table-thead {
                    background: rgba(0, 35, 68, 0.47);

                    tr {
                        border: 0;
                        height: 56px;

                        th {
                            border: 0;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }

                .ant-table-tbody {
                    background-color: rgba(11, 58, 100);

                    tr {
                        border: 0;
                        height: 36px;

                        td {
                            border: 0;
                            border-top: 1px solid #09c8ff;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            opacity: 0.8;
                        }
                    }

                    tr:hover {
                        background: rgba(9, 200, 255, 0.19) !important;
                        border: 1px solid #09c8ff !important;
                        cursor: pointer !important;
                    }
                }

                .ant-table-body::-webkit-scrollbar {
                    background-color: rgba(11, 58, 100);
                    width: 1px;
                }

                .ant-table-body::-webkit-scrollbar-thumb {
                    background-color: rgba(11, 58, 100);
                    background-color: rgba(11, 58, 100);
                }

                .ant-table-placeholder {
                    display: none;
                }

                .ant-table-header {
                    margin-bottom: -18px !important;
                }
            }
        }

        table tbody tr:hover > td {
            background-color: #ffffff !important;
        }

        .not-yet {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #09c8ff;
            line-height: 33px;
            margin-top: 256px;
            text-align: center;
        }

        &::v-deep .ant-pagination {
            .ant-pagination-prev {
                .ant-pagination-item-link {
                    background-color: rgba(11, 58, 100);

                    .anticon {
                        font-size: 16px;
                    }
                }
            }

            li {
                background-color: rgba(11, 58, 100);
            }

            .ant-pagination-item {
                a {
                    color: #ffffff;
                }
            }

            .ant-pagination-item-active {
                a {
                    color: #ffffff;
                }
            }

            .ant-pagination-next {
                .ant-pagination-item-link {
                    background-color: rgba(11, 58, 100);

                    .anticon {
                        font-size: 16px;
                    }
                }
            }

            .ant-pagination-options {
                .ant-pagination-options-quick-jumper {
                    color: #ffffff;

                    input {
                        background-color: rgba(11, 58, 100);
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
</style>
