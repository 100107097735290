<template>
  <div class="AppTable app-table-dialog">
    <div class="title">
      <h3>{{ tableData.title }}</h3>
      <div class="more" @click="onMore">
        更多
        <a-icon style="color: #09c8ff" type="right" />
      </div>
    </div>
    <div class="table-entity">
      <a-table
        class="full-table"
        :columns="tableData.columns"
        size="small"
        :row-key="
          (r, i) => {
            i.toString();
          }
        "
        :data-source="tableData.data"
        :pagination="false"
      >
      </a-table>
      <div class="not-yet" v-if="tableData.data.length <= 0">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    tableData: Object,
  },

  created() {
    console.log(this.tableData);
  },

  mounted() {
    this.title = this.tableData.title;
    this.data = this.tableData.data;
    this.columns = this.tableData.columns;
  },

  methods: {
    onMore() {
      var me = this;
      me.$emit("morehits", me.tableData);
    },
  },
};
</script>
<style lang="scss">
.app-table-dialog {
  table tbody tr:hover > td {
    background-color: #ffffff00 !important;
  }
}
.full-table .ant-table .ant-table-thead tr th {
  border: 0;
  color: #fff !important;
}
</style>


<style lang="scss" scoped>
.AppTable {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  background: url(../../assets/img/pics/031.png) no-repeat center center;
  background-size: 100% 100%;
  border: 1px solid #09c8ff;
  overflow: hidden;

  .title {
    height: 46px;
    position: relative;

    h3 {
      text-align: center;
      height: 100%;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #09c8ff;
      padding-top: 16px;
      margin-bottom: 0;
    }

    .more {
      position: absolute;
      top: 17px;
      right: 8px;
      height: 17px;
      line-height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #09c8ff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .table-entity {
    &::v-deep .full-table {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: 0;

      .ant-table {
        border: 0;

        .ant-table-thead {
          height: 36px;
          background: rgba(0, 35, 68, 0.47);

          tr {
            border: 0;

            th {
              border: 0;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }

        .ant-table-tbody {
          tr {
            border: 0;

            td {
              border: 0;
              padding-top: 2px;
              padding-bottom: 2px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              opacity: 0.8;
            }
          }

          tr:hover {
            background: rgba(9, 200, 255, 0.19) !important;
            border: 1px solid #09c8ff !important;
            cursor: pointer !important;
          }
        }

        .ant-table-placeholder {
          display: none;
        }
      }
    }

    .not-yet {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #09c8ff;
      line-height: 33px;
      margin-top: 16px;
      text-align: center;
    }
  }
}
.full-table {
  .ant-table {
    .ant-table-tbody {
      tr:hover {
        // background: rgba(9, 200, 255, 0.19) !important;
        background: #ff0000 !important;
      }
    }
  }
}

.ant-table-row {
  &:hover {
    background-color: #000 !important;
    color: #fff !important;
  }
  &.row-selection {
    background-color: #000 !important;
    color: #fff !important;
  }
}
</style>
