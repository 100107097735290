<template>
  <div class="FullEchart">
    <div class="title">日数据分析报告</div>
    <div class="tiemAndnav">
      <div class="time">
        <a-date-picker
          size="small"
          style="width: 120px"
          @change="onChange"
          v-model="time"
          :defaultValue="moment(new Date())"
          :format="dateFormat"
        />
      </div>
      <div class="identification">
        <div class="icon-one icon-1"></div>
        <div class="text">超标</div>
        <div class="icon-one icon-2"></div>
        <div class="text">异常</div>
        <div class="icon-one icon-3"></div>
        <div class="text">缺数</div>
        <div class="icon-one icon-4"></div>
        <div class="text">停运</div>
        <div class="icon-one icon-5"></div>
        <div class="text">正常</div>
      </div>
    </div>
    <div class="Echarts-shell">
      <div id="Echarts1"></div>
      <div class="echart-shell">
        <div class="echart" id="Echarts2"></div>
        <div class="echart-legend-title">
          <img :src="touUrl[0]" alt="" />
          <p>废气基站</p>
        </div>
      </div>
      <div class="echart-shell">
        <div class="echart" id="Echarts3"></div>
        <div class="echart-legend-title">
          <img :src="touUrl[1]" alt="" />
          <p>废水基站</p>
        </div>
      </div>
      <div class="echart-shell">
        <div class="echart" id="Echarts4"></div>
        <div class="echart-legend-title">
          <img :src="touUrl[2]" alt="" />
          <p>扬尘基站</p>
        </div>
      </div>
      <div class="echart-shell">
        <div class="echart" id="Echarts5"></div>
        <div class="echart-legend-title">
          <img :src="touUrl[3]" alt="" />
          <p>VOCS基站</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../utils/Http.js";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

import moment from "moment";
import "moment/locale/zh-cn";

export default {
  data() {
    return {
      //图表-数据分析报告
      touUrl: [
        require("../../assets/img/pics/036.png"),
        require("../../assets/img/pics/037.png"),
        require("../../assets/img/pics/038.png"),
        require("../../assets/img/pics/039.png"),
      ],

      moment,
      time: "",
      dateFormat: "YYYY-MM-DD",
      TOTALDAY: dayjs(new Date()).format("YYYY-MM-DD"), //统计时间
    };
  },

  created() {
    //图表
    this.HJJC_DAYTOTAL(this.TOTALDAY);

    var me = this;
    // setInterval(function() {
    // 	me.HJJC_DAYTOTAL(me.TOTALDAY);
    // }, 1000 * 60 * 10)
  },

  mounted() {},

  methods: {
    onChange(item, value) {
      console.log(value);
      this.TOTALDAY = value;
      this.HJJC_DAYTOTAL(value);
    },

    //日统计接口
    HJJC_DAYTOTAL(TOTALDAY) {
      var me = this;
      http({
        url: "/data/q30/HJJC_DAYTOTAL.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: TOTALDAY,
          STATIONTYPE: "",
        },
        success(obj) {
          console.log(obj);

          if (obj.Data2 && obj.Data2.length > 0) {
            me.Echarts1(obj.Data2);
          }

          var data2 = new Array(); //废气基站
          var data3 = new Array(); //废水基站
          var data4 = new Array(); //扬尘基站
          var data5 = new Array(); //VOCS基站

          if (obj.Data && obj.Data.length > 0) {
            for (var i = 0; i < obj.Data.length; i++) {
              if (obj.Data[i].FQ > 0) {
                data2.push({
                  FQ: obj.Data[i].FQ,
                  type: obj.Data[i].type,
                });
              }
              if (obj.Data[i].FS > 0) {
                data3.push({
                  FS: obj.Data[i].FS,
                  type: obj.Data[i].type,
                });
              }
              if (obj.Data[i].YC > 0) {
                data4.push({
                  YC: obj.Data[i].YC,
                  type: obj.Data[i].type,
                });
              }

              if (obj.Data[i].VO > 0) {
                data5.push({
                  VO: obj.Data[i].VO,
                  type: obj.Data[i].type,
                });
              }
            }
          }
          if (data2.length == 0) {
            data2.push({
              FQ: "",
              type: 0,
            });
          }
          if (data3.length == 0) {
            data3.push({
              FS: "",
              type: 0,
            });
          }
          if (data4.length == 0) {
            data4.push({
              YC: "",
              type: 0,
            });
          }
          if (data5.length == 0) {
            data5.push({
              VO: "",
              type: 0,
            });
          }
          console.log(data2);
          console.log(data3);
          console.log(data4);
          console.log(data5);

          me.Echarts2(data2);
          me.Echarts3(data3);
          me.Echarts4(data4);
          me.Echarts5(data5);
        },
        failure(obj) {},
        me,
      });
    },

    Echarts1(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts1"));
      var option = null;

      // var colorList = ['#FF0000', '#800080', '#63A6FF', '#E46363', '#63C972'];
      // var colorList = ['#E60044', '#0054A7', '#EF8300', '#009EA8'];
      let seriesData = [];
      var colorList = ["#0054A7", "#EF8300", "#009EA8", "#CD41FF"];
      for (let i = 0; i < data.length; i++) {
        if (data[i].JZS != 0) {
          switch (Number(data[i].FieldBH)) {
            case 1:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#0054A7",
                },
              });
              break;
            case 2:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#EF8300",
                },
              });
              break;
            case 3:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#009EA8",
                },
              });
              break;
            case 4:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#CD41FF",
                },
              });
              break;
          }
        }
      }
      option = {
        title: {
          text: "基站类型",
          x: "33%",
          y: "center",
          textStyle: {
            fontSize: 14,
            color: "#FFFFFF",
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            center: ["45%", "50%"],
            radius: ["40%", "59%"],
            left: 0,
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       return colorList[params.dataIndex];
            //     },
            //   },
            // },
            avoidLabelOverlap: true,
            labelLine: {
              normal: {
                length: 2,
                length2: 2,
                lineStyle: {
                  width: 1,
                  color: "#FFFFFF",
                },
              },
            },
            label: {
              show: true,
              formatter: "{b}: {c}",
              //   formatter: "{b}",
              color: "#FFFFFF",
              fontSize: 10,
            },
            data: seriesData,
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts2(data) {
      let me = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts2"));
      var option = null;

      //   var colorList = ["#FF0000", "#800080", "#63A6FF", "#FF6F6F", "#63C972"];
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        series: [
          {
            name: "废气基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{c}",
              },
            },
            center: ["50%", "45%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "FQ"),

            // data: [
            //   {
            //     value: data[1].FQ,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].FQ,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].FQ,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].FQ,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].FQ,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts3(data) {
      let me = this;

      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts3"));
      var option = null;

      //   var colorList = ["#FF0000", "#800080", "#63A6FF", "#FF6F6F", "#63C972"];
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: "废水基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{c}",
              },
            },
            center: ["50%", "45%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "FS"),
            // data: [
            //   {
            //     value: data[1].FS,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].FS,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].FS,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].FS,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].FS,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts4(data) {
      let me = this;

      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts4"));
      var option = null;

      //   var colorList = ["#FF0000", "#800080", "#63A6FF", "#FF6F6F", "#63C972"];
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        series: [
          {
            name: "扬尘基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{c}",
              },
            },
            center: ["50%", "45%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "YC"),
            // data: [
            //   {
            //     value: data[1].YC,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].YC,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].YC,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].YC,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].YC,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts5(data) {
      let me = this;

      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts5"));
      var option = null;

      //   var colorList = ["#FF0000", "#800080", "#63A6FF", "#FF6F6F", "#63C972"];
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        series: [
          {
            name: "VOCS基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{c}",
              },
            },
            center: ["50%", "45%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "VO"),
            // data: [
            //   {
            //     value: data[1].VO,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].VO,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].VO,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].VO,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].VO,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    //获取图标数据
    getPieData(data, name) {
      let datas = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].type == 2) {
          datas.push({
            value: data[i][name],
            name: "超标",
            type: 1,
          });
        } else if (data[i].type == 3) {
          datas.push({
            value: data[i][name],
            name: "异常",
            type: 2,
          });
        } else if (data[i].type == 4) {
          datas.push({
            value: data[i][name],
            name: "缺数",
            type: 3,
          });
        } else if (data[i].type == 5) {
          datas.push({
            value: data[i][name],
            name: "停运",
            type: 4,
          });
        } else if (data[i].type == 1) {
          datas.push({
            value: data[i][name],
            name: "正常",
            type: 0,
          });
        } else if (data[i].type == 0) {
          datas.push({
            value: "0",
            name: "",
            type: 0,
          });
        }
      }
      return datas;
    },
  },
};
</script>

<style lang="scss" scoped>
.FullEchart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    height: 60px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #09c8ff;
    line-height: 22px;
    text-align: center;
    padding-top: 14px;
  }

  .tiemAndnav {
    position: absolute;
    z-index: 1;
    right: 18px;
    top: 58px;
    display: flex;
    align-items: center;

    .time {
      margin-right: 16px;

      // &::v-deep .ant-calendar-picker {
      // 	height: 20px;
      // 	background-color: transparent;

      // 	div {
      // 		height: 20px;
      // 		background-color: transparent;

      // 		input {
      // 			background-color: transparent;
      // 		}
      // 	}
      // }
    }

    .identification {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;

      .icon-one {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }

      .icon-1 {
        background: #ff0000;
        border-radius: 3px;
      }

      .icon-2 {
        background: #800080;
        border-radius: 3px;
      }

      .icon-3 {
        background: #63a6ff;
        border-radius: 3px;
      }

      .icon-4 {
        background: #828282;
        border-radius: 3px;
      }

      .icon-5 {
        background: #63c972;
        border-radius: 3px;
      }

      .text {
        margin-right: 8px;
      }

      .text:last-child {
        margin-right: 0;
      }
    }
  }

  .Echarts-shell {
    display: flex;
    flex: 1;
    padding: 0 12px;

    #Echarts1 {
      height: 100%;
      width: 38%;
    }

    .echart-shell {
      flex: 1;
      height: 100%;
      position: relative;

      .echart {
        flex: 1;
        height: 100%;
      }

      .echart-legend-title {
        width: 100%;
        height: 17px;
        position: absolute;
        left: 0;
        bottom: 15px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        p {
          height: 17px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
