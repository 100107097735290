<template>
  <!-- 实时数据 -->
  <div class="real-time-data">
    <!-- 顶部标题 -->
    <div style="display: flex; justify-content: center; padding-bottom: 14px">
      <div class="title">{{ title }}</div>
    </div>
    <vue-seamless-scroll
      v-if="arr.length > 0 || arr1.length > 0"
      :data="scrollList"
      class="seamless-warp"
      :class-option="scrollOptions"
    >
      <ul>
        <li>
          <!-- 废气 -->
          <div v-if="arr.length > 0">
            <div
              class="base-station"
              v-for="(item, index) in arr"
              :key="index + 'a1'"
            >
              <div class="base-station-name">
                <div>
                  {{ item[0].EnterpriseMC }}-{{ item[0].station_name }}-{{
                    item[0].itemtype_name
                  }}
                </div>
                <div style="margin-left: 20px">
                  更新时间：{{ item[0].CYSJ }}
                </div>
              </div>
              <div class="base-station-list">
                <div
                  v-for="(itemObj, index) in item[0].itemData"
                  :key="index + 'b1'"
                >
                  <full-item
                    v-if="itemObj.SS_XSFW == 3"
                    :title="itemObj.SC"
                    :num="`${itemObj.SCZ}`"
                    :numName="itemObj.SCNAME_SS"
                    :numUnit="itemObj.SCUNIT_SS"
                    :number="`${itemObj.ZSZ}`"
                    :numberName="itemObj.ZSNAME_SS"
                    :numberUnit="itemObj.ZSUNIT_SS"
                  ></full-item>

                  <full-item
                    v-if="itemObj.SS_XSFW == 2"
                    :title="itemObj.SC"
                    :num="`${itemObj.ZSZ}`"
                    :numName="itemObj.ZSNAME_SS"
                    :numUnit="itemObj.ZSUNIT_SS"
                  ></full-item>

                  <full-item
                    v-if="itemObj.SS_XSFW == 1"
                    :title="itemObj.SC"
                    :num="`${itemObj.SCZ}`"
                    :numName="itemObj.SCNAME_SS"
                    :numUnit="itemObj.SCUNIT_SS"
                  ></full-item>
                </div>
              </div>
            </div>
          </div>
          <!-- 废水 -->
          <div v-if="arr1.length > 0">
            <div
              class="base-station"
              v-for="(item, index) in arr1"
              :key="index + 'a1'"
            >
              <div class="base-station-name">
                {{ item[0].EnterpriseMC }}-{{ item[0].station_name }}-{{
                  item[0].itemtype_name
                }}
              </div>
              <div class="base-station-list">
                <div
                  v-for="(itemObj, index) in item[0].itemData"
                  :key="index + 'b1'"
                >
                  <full-item
                    v-if="itemObj.SS_XSFW == 3"
                    :title="itemObj.SC"
                    :num="`${itemObj.SCZ}`"
                    :numName="itemObj.SCNAME_SS"
                    :numUnit="itemObj.SCUNIT_SS"
                    :number="`${itemObj.ZSZ}`"
                    :numberName="itemObj.ZSNAME_SS"
                    :numberUnit="itemObj.ZSUNIT_SS"
                  ></full-item>

                  <full-item
                    v-if="itemObj.SS_XSFW == 2"
                    :title="itemObj.SC"
                    :num="`${itemObj.ZSZ}`"
                    :numName="itemObj.ZSNAME_SS"
                    :numUnit="itemObj.ZSUNIT_SS"
                  ></full-item>

                  <full-item
                    v-if="itemObj.SS_XSFW == 1"
                    :title="itemObj.SC"
                    :num="`${itemObj.SCZ}`"
                    :numName="itemObj.SCNAME_SS"
                    :numUnit="itemObj.SCUNIT_SS"
                  ></full-item>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </vue-seamless-scroll>
    <!-- <a-empty
            v-else
            style="height: 100%;align-items: center;display: flex;justify-content: center;;flex-direction: column;"
        /> -->
    <p
      v-else
      style="
        text-align: center;
        font-size: 34px;
        color: #09c8ff;
        padding-top: 120px;
      "
    >
      暂无数据
    </p>
  </div>
</template>

<script>
import FullItem from "@/components/full/FullItem.vue";

import vueSeamlessScroll from "vue-seamless-scroll";

import { http } from "../../utils/Http.js";

export default {
  name: "full-real-time-data-component",
  components: {
    FullItem,
    vueSeamlessScroll,
  },
  data() {
    return {
      title: "实时数据",
      companyName: this.$store.state.loggedIn.EnterpriseMC, // 企业名称
      companyName1: "", // 企业名称
      scrollList: [],
      autoPlay: true,
      scrollOptions: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.scrollList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },

      arr: [], //废气
      arr1: [], //废水
    };
  },

  // created() {
  mounted() {
    this.HJJC_STATION_GAS_WATER_REALDATA_EX1();
  },

  methods: {
    HJJC_STATION_GAS_WATER_REALDATA_EX1() {
      var me = this;
      http({
        url: "/data/HJJC_STATION_GAS_WATER_REALDATA_EX2.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          EnterpriseBH: "",
        },
        success(obj) {
          console.log(obj);

          var arr = new Array(); //废气
          var arr1 = new Array(); //废水

          for (var i = 0; i < 100; i++) {
            var data = obj["Data" + (i == 0 ? "" : i)];
            if (data && data.length > 0) {
              // console.log(data);
              if (data[0].itemkind == 1) {
                //废气
                // arr = [...arr, ...data];
                arr.push(me.getStations(data));
              } else {
                //废水
                // arr1 = [...arr1, ...data];
                arr1.push(me.getStationGs(data));
              }
            }
          }
          // getAjax
          me.arr = arr;
          me.arr1 = arr1;
          // console.log(me.arr);
          // console.log(me.arr1);

          //scrollList 为空不滚动
          if (arr) {
            me.scrollList = arr;
          } else if (arr1) {
            me.scrollList = arr1;
          }
        },
        failure(obj) {},
        me,
      });
    },

    //协议类型为废气时处理数据函数
    getStations(data) {
      var itemArr = [];
      // console.log(data);
      try {
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                EnterpriseMC: data[i].EnterpriseMC,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  EnterpriseMC: data[i].EnterpriseMC,
                  CYSJ: data[i].CYSJ,
                  ConnectState: data[i].ConnectState,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}
      // console.log(itemArr)
      return itemArr;
    },

    //协议类型为废水时处理数据函数
    getStationGs(data) {
      // console.log(data);
      var itemArr = [];
      try {
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            // console.log(data[i].CYSJ);
            // console.log(Ext.util.Format.date(new Date(data[i].CYSJ), 'Y-m-d H:i:s'));
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                EnterpriseMC: data[i].EnterpriseMC,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                // console.log(1);
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  EnterpriseMC: data[i].EnterpriseMC,
                  ConnectState: data[i].ConnectState,
                  CYSJ: data[i].CYSJ,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  // console.log(2);
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}

      // console.log(itemArr)

      if (data && data.length > 0) {
        try {
          for (var k = 0; k < itemArr.length; k++) {
            itemArr[k].aaaa = 0;
          }
        } catch (err) {}
      } else {
        itemArr = [
          {
            aaaa: 1,
          },
        ];
      }

      return itemArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.seamless-warp {
  width: 100%;
  // height: 330px;
  flex: 1;
  overflow: hidden;

  ul {
    padding: 0px;

    li {
      list-style-type: none;
    }
  }
}

.real-time-data {
  width: 100%;
  position: relative;
  height: calc(100vh - 710px);
  display: flex;
  flex-direction: column;

  .title {
    width: 120px;
    height: 32px;
    font-size: 16px;
    color: #ffffff;
    // position: absolute;
    // top: 0px;
    // left: 50%;
    // margin-left: -60px;
    text-align: center;
    line-height: 32px;
  }

  // 公司名称
  .company-name {
    color: #09c8ff;
    font-size: 16px;
    line-height: 38px;
    height: 38px;
    margin-left: 30px;
  }

  // 基站
  .base-station {
    // 基站名称
    .base-station-name {
      margin-left: 30px;
      color: #ffffff;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      flex-direction: row;
    }

    // 基站列表
    .base-station-list {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin: 0px 6px;
      // width: 100%;
      flex-wrap: wrap;
    }
  }
}
</style>
