<template>
  <div id="full">
    <div class="header" v-show="mapScreen">
      <div class="time">{{ newDate }} 星期{{ week }} {{ newTime }}</div>
      <div style="flex: 1; display: flex; justify-content: center">
        <div class="title">{{ title }}污染源在线监控数据平台</div>
      </div>
      <div style="width: 275px; display: flex; justify-content: center">
        <div class="btn" v-show="!fullscreen" @click="handleFullScreen">
          <p>全屏</p>
          <!-- <img :src="fullImg" alt="" /> -->
        </div>
        <div class="btn" style="margin-left: 20px" v-show="!fullscreen" @click="onBack">
          <p>返回</p>
          <!-- <img :src="fullImg2" alt="" /> -->
        </div>
        <div class="btn" v-show="fullscreen" @click="handleFullScreen">
          <p>退出</p>
          <!-- <img :src="fullImg1" alt="" /> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left-table" v-show="mapScreen">
        <div class="table table-a">
          <full-echart style="width: 100%; height: 100%"></full-echart>
        </div>

        <!-- 当前超标企业的数据详情 -->
        <div class="table table-b">
          <app-table1
            @morehits="onMoreHits"
            v-bind:tableData="table1"
            style="width: 100%; height: 100%"
          ></app-table1>
        </div>

        <!-- 当前异常的企业数据详情 -->
        <div class="table table-b">
          <app-table1
            @morehits="onMoreHits"
            v-bind:tableData="table2"
            style="width: 100%; height: 100%"
          ></app-table1>
        </div>

        <!-- 当前缺失的企业详情 -->
        <div class="table table-b">
          <app-table1
            @morehits="onMoreHits"
            v-bind:tableData="table3"
            style="width: 100%; height: 100%"
          ></app-table1>
        </div>

        <!-- 当前停运的企业详情 -->
        <div class="table table-b">
          <app-table1
            @morehits="onMoreHits"
            v-bind:tableData="table4"
            style="width: 100%; height: 100%"
          ></app-table1>
        </div>
      </div>
      <div class="right-div">
        <!-- 右上角地图 -->
        <div class="map">
          <full-map
            @fullScreen="fullScreenChange"
            style="width: 100%; height: 100%"
            @openWindow="onOpenWindow"
          ></full-map>
        </div>
        <!-- 实时数据 -->
        <div class="real-time-table" v-show="mapScreen">
          <real-table></real-table>
        </div>
      </div>
    </div>
    <!-- 左侧列表、点击更多显示的弹窗 -->
    <a-modal
      dialogClass="a-modal-table"
      centered
      :visible="visible"
      width="1130px"
      @cancel="handleCancel"
    >
      <modal-table :itemData="itemData"></modal-table>
    </a-modal>

    <!-- 右上角地图内基站点击后，要显示的弹窗。 -->
    <a-modal
      class="full-screen-model"
      v-model="mapDialog"
      centered
      :footer="null"
      wrapClassName="map-dialog-wrapper"
    >
      <!-- MapPopUpWindows -->
      <div slot="title" class="dialog-title">基站详情</div>
      <img slot="closeIcon" src="/map/close.png" style="width: 24px; height: 24px" />
      <map-pop-up-windows v-bind:stationId="stationId"></map-pop-up-windows>
    </a-modal>
  </div>
</template>

<script>
import { http } from "../../utils/Http.js";

import moment from "moment";
import "moment/locale/zh-cn";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

import RealTable from "./RealTable.vue";
import FullMap from "./FullMap.vue";
import AppTable1 from "./AppTable1.vue";
import FullEchart from "./FullEchart.vue";
import ModalTable from "./ModalTable.vue";

import MapPopUpWindows from "@/components/MapPopUpWindows.vue";

export default {
  components: {
    RealTable,
    FullMap,
    AppTable1,
    FullEchart,
    ModalTable,
    MapPopUpWindows,
  },
  data() {
    return {
      fullscreen: false, //全屏
      fullImg: require("../../assets/img/pics/030.png"),
      fullImg1: require("../../assets/img/pics/033.png"),
      fullImg2: require("../../assets/img/pics/041.png"),
      newDate: "",
      newTime: "",
      week: "",
      title: "",

      //地图全屏
      mapScreen: true,

      table1: {
        title: "当前超标的企业数据详情",
        data: [],
        type: 2,
        columns: [
          {
            title: "时间",
            align: "center",
            dataIndex: "BJSJ",
            ellipsis: true,
          },
          {
            title: "企业名称",
            align: "center",
            dataIndex: "EnterpriseMC",
            ellipsis: true,
          },
          {
            title: "基站名称",
            align: "center",
            dataIndex: "station_name",
            ellipsis: true,
          },
          {
            title: "监测因子",
            align: "center",
            dataIndex: "ItemName",
            ellipsis: true,
          },
          {
            title: "超标数值",
            align: "center",
            dataIndex: "DQZ",
            ellipsis: true,
          },
          {
            title: "排放标准(mg/m³)",
            align: "center",
            dataIndex: "PCBZ",
            ellipsis: true,
          },
          {
            title: "超标倍数",
            align: "center",
            dataIndex: "CBBS",
            ellipsis: true,
          },
          {
            title: "联系方式",
            align: "center",
            dataIndex: "phone",
            ellipsis: true,
          },
        ],
      },

      table2: {
        title: "当前异常的企业数据详情",
        data: [],
        type: 1,
        columns: [
          {
            title: "时间",
            align: "center",
            dataIndex: "BJSJ",
            ellipsis: true,
          },
          {
            title: "企业名称",
            align: "center",
            dataIndex: "EnterpriseMC",
            ellipsis: true,
          },
          {
            title: "基站名称",
            align: "center",
            dataIndex: "station_name",
            ellipsis: true,
          },
          {
            title: "监测因子",
            align: "center",
            dataIndex: "ItemName",
            ellipsis: true,
          },
          {
            title: "异常数值",
            align: "center",
            dataIndex: "DQZ",
            ellipsis: true,
          },
          {
            title: "异常类型",
            align: "center",
            dataIndex: "YCLX",
            ellipsis: true,
            customRender: (text, record, index) => {
              // (1:最大值 2：最小值)
              let name = "";

              if (text == 1) {
                name = "最大值";
              } else if (text == 2) {
                name = "最小值";
              }
              return name;
            },
          },
          {
            title: "联系方式",
            align: "center",
            ellipsis: true,
            dataIndex: "phone",
          },
        ],
      },

      table3: {
        title: "当前缺失的企业详情",
        data: [],
        type: 3,
        columns: [
          {
            title: "企业名称",
            align: "center",
            dataIndex: "EnterpriseMC",
            ellipsis: true,
          },
          {
            title: "基站名称",
            align: "center",
            dataIndex: "station_name",
            ellipsis: true,
          },
          {
            title: "基站类型",
            align: "center",
            dataIndex: "station_Type",
            ellipsis: true,
          },
          {
            title: "传输率",
            align: "center",
            dataIndex: "CSL",
            ellipsis: true,
          },
          {
            title: "联系方式",
            align: "center",
            dataIndex: "",
            ellipsis: true,
          },
        ],
      },

      table4: {
        title: "当前停运的企业详情",
        data: [],
        type: 4,
        columns: [
          {
            title: "停运时段",
            align: "center",
            dataIndex: "TYSD",
            ellipsis: true,
          },
          {
            title: "企业名称",
            align: "center",
            dataIndex: "EnterpriseMC",
            ellipsis: true,
          },
          {
            title: "基站名称",
            align: "center",
            dataIndex: "station_name",
            ellipsis: true,
          },
          {
            title: "基站类型",
            align: "center",
            dataIndex: "station_Type",
            ellipsis: true,
          },
          {
            title: "停运报告",
            align: "center",
            dataIndex: "ItemName",
            ellipsis: true,
          },
          {
            title: "停运原因",
            align: "center",
            dataIndex: "TYYY",
            ellipsis: true,
          },
          {
            title: "联系方式",
            align: "center",
            dataIndex: "",
            ellipsis: true,
          },
        ],
      },

      visible: false,

      //弹框数据
      itemData: null,

      // map 地图相关数据
      mapDialog: false,
      stationId: "", // 地图基站编号
    };
  },

  created() {
    var me = this;

    if (localStorage.getItem("loggedIn")) {
      var loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      this.$store.commit("setLoggedIn", loggedIn);
    } else {
      this.$router.push("/login");
    }

    var TOTALDAY = dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"); //统计时间
    me.newDate = TOTALDAY.split(" ")[0];
    me.newTime = TOTALDAY.split(" ")[1];
    var weekNumber = dayjs(new Date()).day();
    switch (weekNumber) {
      case 0:
        me.week = "日";
        break;
      case 1:
        me.week = "一";
        break;
      case 2:
        me.week = "二";
        break;
      case 3:
        me.week = "三";
        break;
      case 4:
        me.week = "四";
        break;
      case 5:
        me.week = "五";
        break;
      case 6:
        me.week = "六";
        break;
      default:
        me.week = "日";
    }

    // setInterval(function() {
    // 	var TOTALDAY = dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"); //统计时间
    // 	me.newDate = TOTALDAY.split(' ')[0];
    // 	me.newTime = TOTALDAY.split(' ')[1];
    // 	var weekNumber = dayjs(new Date()).day();
    // 	switch (weekNumber) {
    // 		case 0:
    // 			me.week = '日'
    // 			break;
    // 		case 1:
    // 			me.week = '一'
    // 			break;
    // 		case 2:
    // 			me.week = '二'
    // 			break;
    // 		case 3:
    // 			me.week = '三'
    // 			break;
    // 		case 4:
    // 			me.week = '四'
    // 			break;
    // 		case 5:
    // 			me.week = '五'
    // 			break;
    // 		case 6:
    // 			me.week = '六'
    // 			break;
    // 		default:
    // 			me.week = '日'
    // 	}
    // }, 1000);

    // this.title = `${loggedIn.XMC}`;
    var loggedIn = this.$store.state.loggedIn;
    let title = "";

    if (loggedIn.SFMC) {
      title = loggedIn.SFMC;
    }

    if (loggedIn.SMC) {
      title = loggedIn.SMC;
    }

    if (loggedIn.XMC) {
      title = loggedIn.XMC;
    }

    if (title == null) {
      this.title = "";
    } else {
      this.title = title;
    }
  },

  mounted() {
    // 报警类型（1异常2：超标）
    this.HJJC_DAYTOTAL_NEW_ABNORMAL_LIST(1);
    this.HJJC_DAYTOTAL_NEW_ABNORMAL_LIST(2);
    this.HJJC_DAYTOTAL_NEW_LACK_LIST();
    this.HJJC_STATIONLIST_OUTAGE_LIST();

    var me = this;
    // setInterval(function() {
    // 	me.HJJC_DAYTOTAL_NEW_ABNORMAL_LIST(1);
    // 	me.HJJC_DAYTOTAL_NEW_ABNORMAL_LIST(2);
    // 	me.HJJC_DAYTOTAL_NEW_LACK_LIST();
    // 	me.HJJC_STATIONLIST_OUTAGE_LIST();

    // }, 1000 * 60 * 10)
  },

  methods: {
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },

    onBack() {
      this.$router.go(-1); //返回上一层
    },

    // 日数据基站异常
    HJJC_DAYTOTAL_NEW_ABNORMAL_LIST(BJLX) {
      var me = this;
      // console.log(me.$store.state.loggedIn);
      http({
        url: "/data/q30/HJJC_DAYTOTAL_NEW_ABNORMAL_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.newDate,
          BJLX: BJLX,
          PAGESIZE: 15,
          PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);

          // 报警类型    （1异常  2：超标）

          if (BJLX == 2) {
            me.table1.data = obj.Data;
          }

          if (BJLX == 1) {
            me.table2.data = obj.Data;
          }
        },
        failure(obj) {},
        me,
      });
    },

    //基站缺失详情
    HJJC_DAYTOTAL_NEW_LACK_LIST() {
      var me = this;
      // console.log(me.$store.state.loggedIn);
      http({
        url: "/data/q30/HJJC_DAYTOTAL_NEW_LACK_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.newDate,
          PAGESIZE: 15,
          PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);
          me.table3.data = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    // 基站停运列表
    HJJC_STATIONLIST_OUTAGE_LIST() {
      var me = this;
      http({
        url: "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          SFBH: "",
          SBH: "",
          XBH: "",
          JZMC: "",
          SXZM: "",
          KSSJ: me.newDate,
          JSSJ: me.newDate,
          start: 0,
          limit: 15,
        },
        success(obj) {
          console.log(obj);
          me.table4.data = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 左侧列表的 展示更多 按钮点击事件。
     */
    onMoreHits(item) {
      console.log(item);
      this.itemData = item;
      this.visible = true;
    },

    handleCancel(e) {
      this.visible = false;
    },

    /**
     * 接收右上角地图内，基站的点击事件。
     */
    onOpenWindow(id) {
      console.log("第二步" + id);
      let me = this;
      me.mapDialog = true;
      me.stationId = id;
    },

    /**
     * 右上角 全屏展示与关闭的按钮
     */
    fullScreenChange(type) {
      let me = this;
      console.log(type);
      me.mapScreen = type;
    },
  },
};
</script>

<style lang="scss" scoped>
#full {
  width: 100%;
  height: 100%;
  min-width: 1920px;
  min-height: 1080px;
  background-color: #0b3a64;
  padding: 0 20px 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  flex-direction: column;

  .header {
    height: 70px;
    margin-bottom: 39px;
    // height: 39px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    padding: 0px;
    // background-color: #164a82;

    .time {
      // margin-right: 249px;
      width: 275px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      // background: linear-gradient(180deg, #205d8d 0%, #3d95ff 100%);
      // border: 1px solid #09c8ff;
      // opacity: 0.8;
      font-family: PingFangSC-Medium, PingFang SC;
    }

    .title {
      // width: 1234px;
      width: 880px;
      height: 82px;
      padding-top: 16px;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
      text-align: center;
      background: url("../../assets/img/pics/034-1.png") no-repeat center center;
      background-size: 100% 100%;
    }

    .btn {
      // background: url("../../assets/img/pics/029.png") no-repeat center
      //     center;
      // background-size: 100% 100%;
      width: 80px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ffffff;
      cursor: pointer;

      p {
        margin-right: 6px;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;

    .left-table {
      width: 730px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .table {
        background: url(../../assets/img/pics/031.png) no-repeat center center;
        background-size: 100% 100%;
        border: 1px solid #09c8ff;
        margin-bottom: 19px;
      }

      .table:last-child {
        margin-bottom: 0;
      }

      .table-a {
        width: 100%;
        height: 249px;
        background: url(../../assets/img/pics/035.png) no-repeat center center;
        background-size: 100% 100%;
        display: flex;
      }

      .table-b {
        // width: 100%;
        flex: 1;
        height: 156px;
        max-height: 156px;
      }
    }

    .right-div {
      flex: 1;
      display: flex;
      flex-direction: column;

      .map {
        // height: 550px;
        flex: 1;
        margin-bottom: 20px;
        background: rgba(13, 57, 100, 0.23);
        border: 1px solid #09c8ff;
      }

      .real-time-table {
        height: 40.0073%;
        background: url(../../assets/img/pics/032.png) no-repeat center center;
        background-size: 100% 100%;
        overflow: hidden;
      }
    }
  }
}

// 右上角地图内，基站点击后，弹窗的样式
.map-dialog-wrapper {
  // border:1px solid red;
  // padding: 0px;
  // width: 1130px;
  .dialog-title {
    background-color: #0b3a64;
    text-align: center;
    height: 60px;
    line-height: 60px;
    color: #09c8ff;
    font-size: 22px;
  }
}
</style>

<style lang="scss">
.full-screen-model {
  // 右上角地图内，基站点击后，弹窗的样式
  .map-dialog-wrapper {
    .ant-modal-header {
      padding: 0px !important;
      // width: 1130px;
      color: #09c8ff;
      border-left: 1px solid #09c8ff;
      border-top: 1px solid #09c8ff;
      border-right: 1px solid #09c8ff;
      border-bottom: none;
    }

    .ant-modal {
      width: 1200px !important;
    }

    .ant-modal-body {
      background-color: #0b3a64;
      padding: 0px !important;
      border-left: 1px solid #09c8ff;
      border-bottom: 1px solid #09c8ff;
      border-right: 1px solid #09c8ff;
    }
  }
}

.a-modal-table {
  .ant-modal-content {
    background: url(../../assets/img/pics/040.png) no-repeat center center;
    background-size: 100% 100%;

    .anticon {
      font-size: 28px;
      color: #ffffff;
    }

    .ant-modal-body {
      padding: 0px;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}
</style>
