<template>
  <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler">
    <!-- markers 展现区 -->
    <bml-marker-clusterer :averageCenter="true" :styles="customStyles">
      <bm-marker
        v-for="(item, index) in markers"
        :key="index"
        :position="{ lng: item.station_long, lat: item.station_lat }"
        :icon="{
          url: '/map/' + item.url,
          size: {
            width: 30,
            height: 30,
          },
        }"
        @click="onClickStation(item)"
      >
      </bm-marker>
    </bml-marker-clusterer>

    <!-- 右上角的全屏展示: 功能没做，弹窗需要自定义弹窗，没想好怎么做。 -->
    <bm-control
      anchor="BMAP_ANCHOR_TOP_RIGHT"
      :offset="{ width: 15, height: 15 }"
    >
      <img
        class="full-screen"
        :src="fullScreen ? '/map/legend/magnify.png' : '/map/legend/shrink.png'"
        @click="fullScreenChange"
      />
    </bm-control>

    <!-- 右下角详情展示区 -->
    <bm-control anchor="BMAP_ANCHOR_BOTTOM_RIGHT">
      <!-- 隐藏 -->
      <div class="legend-hidden" v-show="isLegend">
        <div class="legend-left" @click="legendChange">
          <a-icon type="left" />
        </div>
      </div>
      <!-- 显示 -->
      <div class="legend-show" v-show="!isLegend">
        <div class="legend-left" @click="legendChange">
          <a-icon type="right" />
        </div>
        <div class="legend-center map-legend">
          <div
            class="map-legend-item"
            v-for="(item, index) in legendCenter"
            :key="index"
          >
            <div
              v-if="item.color"
              class="icon"
              :style="{ backgroundColor: item.color }"
            ></div>
            <div :class="[!!item.color ? 'fs14' : 'fs12']">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="legend-right">
          <div class="legend-title">图例</div>
          <div class="map-legend">
            <div
              class="map-legend-item"
              v-for="(item, index) in legendRight"
              :key="index"
            >
              <img
                v-if="item.img"
                class="img"
                :src="item.img"
                :alt="item.title"
              />
              <div :class="[!!item.img ? 'fs14' : 'fs12']">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </bm-control>
  </baidu-map>
</template>

<script>
import { BmlMarkerClusterer } from "vue-baidu-map";
import { http } from "@/utils/Http.js";
import dayjs from "dayjs";

export default {
  name: "full-map-page",
  components: {
    BmlMarkerClusterer,
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,

      BMap: {},
      map: {},
      markers: [],
      markers1: [
        {
          lng: 116.455,
          lat: 39.906,
          url: "/map/fq.png",
        },
        {
          lng: 116.505,
          lat: 39.906,
          url: "/map/fs.png",
        },
        {
          lng: 116.355,
          lat: 39.916,
          url: "/map/yc.png",
        },
        {
          lng: 116.605,
          lat: 39.816,
          url: "/map/vocs.png",
        },
        {
          lng: 116.705,
          lat: 39.916,
          url: "/map/fq-lx.png",
        },
      ],
      // 地图聚合绑定的 style 数组
      customStyles: [
        {
          url: "/map/jh.png",
          size: { width: 30, height: 30 },
          opt_anchor: [40, 35],
          textColor: "#ff0000",
          opt_textSize: 12,
        },
      ],
      // 右上方全屏按钮
      fullScreen: true,

      // 右下方 legend 展示区域
      isLegend: false, // 右下角 legend 是否显示。
      legendCenter: [
        {
          title: "状态",
        },
        {
          color: "#0080FF",
          title: "在线",
        },
        {
          color: "#808080",
          title: "离线",
        },
        {
          color: "#800080",
          title: "异常",
        },
        {
          color: "#FF0000",
          title: "超标",
        },
        {
          color: "#FF8000",
          title: "预警",
        },
      ],
      legendRight: [
        {
          title: "基站类型",
        },
        {
          title: "废气",
          img: "/map/fq.png",
        },
        {
          title: "废水",
          img: "/map/fs.png",
        },
        {
          title: "扬尘",
          img: "/map/yc.png",
        },
        {
          title: "VOCS",
          img: "/map/vocs.png",
        },
        {
          title: "聚合",
        },
        {
          title: "基站聚合",
          img: "/map/jh.png",
        },
      ],
    };
  },
  computed: {},
  methods: {
    handler({ BMap, map }) {
      // // // console.log(BMap, map);
      let me = this;
      me.BMap = BMap;
      me.map = map;
      // me.center.lng = 116.404;
      // me.center.lat = 39.915;
      me.zoom = 10;
      // 主题
      let mapStyle = { style: "midnight" };
      map.setMapStyle(mapStyle);

      // 滚轮缩放
      map.enableScrollWheelZoom();

      me.getAjax();
    },

    /**
     * 地图左下角 legend 的隐藏与显示
     */
    legendChange() {
      let me = this;
      me.isLegend = !me.isLegend;
      // // console.log(me.isLegend);
    },
    /**
     * 右上角 全屏展示与关闭的按钮
     */
    fullScreenChange() {
      let me = this;

      me.fullScreen = !me.fullScreen;

      this.$emit("fullScreen", me.fullScreen);

      this.handler();
    },

    /**
     * 地图内基站点击事件
     */
    onClickStation(item) {
      // // console.log(item,item.url);
      let me = this;
      // console.log('第一步'+item.station_bh);
      me.$emit("openWindow", item.station_bh);
    },

    /**
     * 请求接口，获取基站列表
     */
    getAjax() {
      let me = this;
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        TOTALDAY: dayjs().format("YYYY-MM-DD"),
      };
      http({
        url: "/data/q30/HJJC_DAYTOTAL_NEW_LIST.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.setCentre(obj.Data[0]); // 设置中心店
          let markers = me.getMarkers(obj.Data);
          // // console.log(markers);
          me.markers = markers;
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 设置地图中心点
     */
    setCentre({ station_long, station_lat }) {
      let me = this;
      // me.center.lng = 116.404;
      // me.center.lat = 39.915;
      // // console.log(station_long,station_lat);
      me.center.lng = station_long;
      me.center.lat = station_lat;
    },
    /**
     * 获取marker数组
     */
    getMarkers(obj) {
      let me = this;
      obj.forEach((item) => {
        item.url = me.getMarkerImg(item);
        // console.log(item.url);
      });
      return obj;
    },

    /**
     * 获取 marker 的图片 url
     */
    getMarkerImg(item) {
      let url = ``;
      switch (item.station_Type) {
        case 1:
        case "1":
          url = "fq";
          break;
        case 2:
        case "2":
          url = "fs";
          break;
        case 3:
        case "3":
          url = "yc";
          break;
        case 4:
        case "4":
          url = "vocs";
          break;
      }
      // 离线
      if (item.ConnectState == 0 || item.ConnectState == "0") {
        return `${url}-lx.png`;
      } else {
        // 异常图标
        if (item.YC == 1 || item.YC == "1") {
          return `${url}-yc.png`;
        }
        // 超标图标
        if (item.CB == 1 || item.CB == "1") {
          return `${url}-cb.png`;
        }
        // 预警图标
        if (item.YJ == 1 || item.YJ == "1") {
          return `${url}-yj.png`;
        }
        return `${url}.png`;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 550px;

  .fs12 {
    font-size: 12px;
  }
  .fs14 {
    font-size: 14px;
  }

  // legend 的样式

  // 右上角
  .full-screen {
    display: block;
    width: 32px;
    height: 32px;
  }

  // 右下角
  .map-legend {
    display: flex;
    width: 100%;
    color: #fff;
    flex-direction: column;
    flex: 1;

    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .map-legend-item {
      display: flex;
      flex: 1;
      align-items: center;
      .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .img {
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .legend-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 48px;
    word-spacing: 10px;
    letter-spacing: 10px;
    color: #fff;
    border-bottom: 1px solid #09c8ff;
  }
  .legend-hidden {
    display: flex;
    width: 40px;
    height: 165px;
    .legend-left {
      height: 80px;
      width: 40px;
      color: #09c8ff;
      background-image: url(/map/legend/legend-l.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .legend-show {
    display: flex;
    width: 280px;
    height: 328px;
    .legend-right {
      height: 328px;
      width: 120px;
      padding-left: 15px;
      background-image: url(/map/legend/legend-r.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
    }
    .legend-center {
      height: 250px;
      width: 120px;
      padding-left: 20px;
      margin-top: 78px;
      background-image: url(/map/legend/legend-c.png);
      background-size: 100% 100%;
    }
    .legend-left {
      margin-top: 163px;
      height: 80px;
      width: 40px;
      color: #09c8ff;
      background-image: url(/map/legend/legend-l.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style >
/* 消除地图左下角的logo和证件号码 */
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}
</style>
