import { render, staticRenderFns } from "./AppTable1.vue?vue&type=template&id=cf78cb48&scoped=true&"
import script from "./AppTable1.vue?vue&type=script&lang=js&"
export * from "./AppTable1.vue?vue&type=script&lang=js&"
import style0 from "./AppTable1.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AppTable1.vue?vue&type=style&index=1&id=cf78cb48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf78cb48",
  null
  
)

export default component.exports