<template>
    <div class="" :class="[!!number?'w262':'w125', 'full-item']">
        <div class="top"></div>
        <div class="title">{{ title }}</div>
        <div class="child">
            <div class="child-item">
                <p>{{ num }}</p>
                <p>{{ numName }}</p>
                <p>{{ numUnit }}</p>
            </div>
            <div class="child-item" v-if="number && numberName && numberUnit">
                <p>{{ number }}</p>
                <p>{{ numberName }}</p>
                <p>{{ numberUnit }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "full-item-component",
    props: {
        title: {
            type: String,
            default() {
                return "";
            },
        },
        num: {
            type: String,
            default() {
                return "";
            },
        },
        numName: {
            type: String,
            default() {
                return "";
            },
        },
        numUnit: {
            type: String,
            default() {
                return "";
            },
        },
        number: {
            type: String,
            default() {
                return "";
            },
        },
        numberName: {
            type: String,
            default() {
                return "";
            },
        },
        numberUnit: {
            type: String,
            default() {
                return "";
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.w220{
    width: 220px;
}
.w160{
    width: 160px ;
}
.w262{
    width: 262px;
}
.w125{
    width: 125px;
}
.full-item {
    // width: 100%;
    color: #ffffff;
    border:1px solid #09c8ff;
    border-radius: 5px;
    position: relative;
    margin: 6px;
    min-height: 112px;
    text-align: center;
    background-color: #09c8ff; /* 浏览器不支持的时候显示 */
    background-image: linear-gradient(
        #184478,
        #1f5ba6,
        #184478
    ); /* 标准的语法（必须放在最后） */
    .top {
        width: 58px;
        height: 6px;
        border-radius: 2px;
        background-color: #09c8ff;
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -29px;
    }
    .title {
        width: 100%;
        font-size: 14px;
        text-align: center;
        line-height: 42px;
        height: 36px;
        border-bottom: 1px solid #09c8ff;
    }
    .child {
        display: flex;
        width: 100%;
        .child-item {
            display: flex;
            padding: 6px 0px;
             flex-direction:column;
             flex: 1;
             
            p {
                margin: 0px;
                padding: 0px;
                width: 100%;
                flex: 1;
                font-size: 14px;
                height: 18px;
                line-height: 1.3;
            }
            p:first-child {
                flex: 2;
                font-size: 20px;
                height: 26px;
                font-weight: bold;
            }
        }
        .child-item:nth-of-type(2) {
            border-left: 1px solid #09c8ff;
        }
    }
}
</style>