<template>
  <div class="map-pop-up-windows" style="padding-top: 1px">
    <!-- {{stationId}} -->

    <div class="item-title">基本信息</div>
    <div class="base">
      <div class="title wrapper">
        <div class="wrapper-item flex3">企业名称</div>
        <div class="wrapper-item flex1">基站名称</div>
        <div class="wrapper-item flex1">基站类型</div>
        <div class="wrapper-item flex1">状态</div>
        <div class="wrapper-item flex3">运维单位</div>
        <div class="wrapper-item flex1">联系方式</div>
      </div>
      <div class="content wrapper">
        <div class="wrapper-item flex3">{{ base.EnterpriseMC }}</div>
        <div class="wrapper-item flex1">{{ base.station_name }}</div>
        <div class="wrapper-item flex1">{{ base.station_type }}</div>
        <div class="wrapper-item flex1">
          {{ base.ConnectState == 1 ? "在线" : "下线" }}
        </div>
        <div class="wrapper-item flex3" wrapper-item-width>
          {{ base.Operator_MC }}
        </div>
        <div class="wrapper-item flex1">{{ base.phone }}</div>
      </div>
    </div>

    <div class="item-title">基站实时报警数据</div>
    <div class="data">
      <div class="big wrapper">
        <div class="top"></div>
        <div class="line-end line">
          <span>{{ shuju.sum_error }}</span>
          <span>个</span>
        </div>
        <div class="line">
          <img src="/map/dialog/bj.png" alt="" />
          <p>报警总量</p>
        </div>
      </div>
      <div class="small wrapper">
        <div class="line-end line">
          <span>{{ shuju.Abnormal_num }}</span>
          <span>个</span>
        </div>
        <div class="line">
          <img src="/map/dialog/yc.png" alt="" />
          <p>异常</p>
        </div>
      </div>
      <div class="small wrapper">
        <div class="line-end line">
          <span>{{ shuju.Overproof_num }}</span>
          <span>个</span>
        </div>
        <div class="line">
          <img src="/map/dialog/cb.png" alt="" />
          <p>超标</p>
        </div>
      </div>
      <div class="small wrapper">
        <div class="line-end line">
          <span>{{ shuju.Early_num }}</span>
          <span>个</span>
        </div>
        <div class="line">
          <img src="/map/dialog/yj.png" alt="" />
          <p>预警</p>
        </div>
      </div>
    </div>

    <div class="item-title">
      实时数据<span style="font-size: 12px; margin-left: 20px"
        >更新时间:&nbsp;&nbsp;{{ RealTimeUpdate }}</span
      >
    </div>
    <div class="real-time">
      <!-- 废气 -->
      <div v-if="arr.length > 0" style="width: 100%">
        <div
          class="base-station"
          v-for="(item, index) in arr"
          :key="index + 'a1'"
        >
          <div class="base-station-list">
            <div
              v-for="(itemObj, index) in item[0].itemData"
              :key="index + 'b1'"
            >
              <full-item
                v-if="itemObj.SS_XSFW == 3"
                :title="itemObj.SC"
                :num="`${itemObj.SCZ}`"
                :numName="itemObj.SCNAME_SS"
                :numUnit="itemObj.SCUNIT_SS"
                :number="`${itemObj.ZSZ}`"
                :numberName="itemObj.ZSNAME_SS"
                :numberUnit="itemObj.ZSUNIT_SS"
              ></full-item>

              <full-item
                v-if="itemObj.SS_XSFW == 2"
                :title="itemObj.SC"
                :num="`${itemObj.ZSZ}`"
                :numName="itemObj.ZSNAME_SS"
                :numUnit="itemObj.ZSUNIT_SS"
              ></full-item>

              <full-item
                v-if="itemObj.SS_XSFW == 1"
                :title="itemObj.SC"
                :num="`${itemObj.SCZ}`"
                :numName="itemObj.SCNAME_SS"
                :numUnit="itemObj.SCUNIT_SS"
              ></full-item>
            </div>
          </div>
        </div>
      </div>

      <!-- 废水 -->
      <div v-if="arr1.length > 0" style="width: 100%">
        <div
          class="base-station"
          v-for="(item, index) in arr1"
          :key="index + 'a1'"
        >
          <div class="base-station-list">
            <div
              v-for="(itemObj, index) in item[0].itemData"
              :key="index + 'b1'"
            >
              <full-item
                v-if="itemObj.SS_XSFW == 3"
                :title="itemObj.SC"
                :num="`${itemObj.SCZ}`"
                :numName="itemObj.SCNAME_SS"
                :numUnit="itemObj.SCUNIT_SS"
                :number="`${itemObj.ZSZ}`"
                :numberName="itemObj.ZSNAME_SS"
                :numberUnit="itemObj.ZSUNIT_SS"
              ></full-item>

              <full-item
                v-if="itemObj.SS_XSFW == 2"
                :title="itemObj.SC"
                :num="`${itemObj.ZSZ}`"
                :numName="itemObj.ZSNAME_SS"
                :numUnit="itemObj.ZSUNIT_SS"
              ></full-item>

              <full-item
                v-if="itemObj.SS_XSFW == 1"
                :title="itemObj.SC"
                :num="`${itemObj.SCZ}`"
                :numName="itemObj.SCNAME_SS"
                :numUnit="itemObj.SCUNIT_SS"
              ></full-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullItem from "@/components/full/FullItem.vue";
import { http } from "@/utils/Http.js";
export default {
  name: "map-pop-up-window",
  props: {
    stationId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      RealTimeUpdate: "", //实时数据更新时间
      base: {},
      shuju: {},
      arr: [],
      arr1: [],
    };
  },
  components: {
    FullItem,
  },
  computed: {},
  watch: {
    stationId(val) {
      console.log(val);
      let me = this;
      me.getAjax();
      // return (stationId) => {
      //     let me = this;
      //     console.log("第三部"+stationId);
      //     me.getAjax();
      // };
    },
  },
  mounted() {
    let me = this;
    console.log(`子类的id：${me.stationId}`);
    me.getAjax();
  },
  methods: {
    getAjax() {
      let me = this;
      console.log(me.stationId);
      if (!me.stationId) {
        return;
      }
      console.log(`弹窗内的数据：：：${me.stationId}`);
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        station_bh: me.stationId,
      };
      http({
        url: "/data/q30/HJJC_STATIONDETAILS_Ext_Plus.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.base = obj.Data[0];
          me.shuju = obj.Data1[0];
          if (obj.Data2.length > 0) {
            me.RealTimeUpdate = obj.Data2[0].CYSJ.replace("T", " ");
          }

          var arr = new Array(); //废气
          var arr1 = new Array(); //废水

          for (var i = 2; i < 100; i++) {
            var data = obj["Data" + (i == 0 ? "" : i)];
            if (data && data.length > 0) {
              console.log(data);
              if (data[0].itemkind == 1) {
                //废气
                // arr = [...arr, ...data];
                arr.push(me.getStations(data));
              } else {
                //废水
                // arr1 = [...arr1, ...data];
                arr1.push(me.getStationGs(data));
              }
            }
          }
          // getAjax
          me.arr = arr;
          me.arr1 = arr1;
          console.log(arr);
          console.log(arr1);
        },
        failure(obj) {},
        me,
      });
    },

    //协议类型为废气时处理数据函数
    getStations(data) {
      var itemArr = [];
      console.log(data);
      try {
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  CYSJ: data[i].CYSJ,
                  ConnectState: data[i].ConnectState,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}
      // console.log(itemArr)
      return itemArr;
    },

    //协议类型为废水时处理数据函数
    getStationGs(data) {
      console.log(data);
      var itemArr = [];
      try {
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            // console.log(data[i].CYSJ);
            // console.log(Ext.util.Format.date(new Date(data[i].CYSJ), 'Y-m-d H:i:s'));
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                // console.log(1);
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  ConnectState: data[i].ConnectState,
                  CYSJ: data[i].CYSJ,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  // console.log(2);
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}

      // console.log(itemArr)

      if (data && data.length > 0) {
        try {
          for (var k = 0; k < itemArr.length; k++) {
            itemArr[k].aaaa = 0;
          }
        } catch (err) {}
      } else {
        itemArr = [
          {
            aaaa: 1,
          },
        ];
      }

      return itemArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.map-pop-up-windows {
  // 每一项的title
  .item-title {
    margin: 20px 0px 12px 20px;
    color: #09c8ff;
    font-size: 16px;
    line-height: 22px;
  }
  .item-title:first-child {
    margin-top: 0px;
  }
  // 基本信息
  .base {
    padding: 0px 20px 10px 20px;
    border-bottom: 1px solid #09c8ff;
    .wrapper {
      display: flex;
      text-align: center;
      color: #fff;
      font-size: 14px;
      line-height: 36px;
      height: 36px;

      .wrapper-item-width {
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .title {
      background-color: #002344;
    }
    .flex3 {
      flex: 3;
    }
    .flex1 {
      flex: 1;
    }
  }

  // 基站实时报警数据
  .data {
    padding: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #09c8ff;
    .big {
      width: 220px;
      padding: 13px 0px 10px 0px;
    }
    .small {
      width: 130px;
      padding: 5px 0px 5px 0px;
    }
    .wrapper {
      // #164C79
      // #2467B1
      color: #ffffff;
      border: 1px solid #09c8ff;
      border-radius: 5px;
      position: relative;
      margin: 0px 0px 0px 40px;

      text-align: center;
      background-color: #164c79; /* 浏览器不支持的时候显示 */
      background-image: linear-gradient(
        #164c79,
        #2467b1
      ); /* 标准的语法（必须放在最后） */
      .top {
        width: 58px;
        height: 6px;
        border-radius: 2px;
        background-color: #09c8ff;
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -29px;
      }
      .line-end {
        align-items: flex-end !important;
      }
      .line {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0px;
          padding: 0px;
          font-size: 16px;
        }
        img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        span {
          font-size: 18px;
        }
        span:first-child {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
  }
  // 实时数据
  .real-time {
    padding-bottom: 45px;
  }
  .base-station {
    // 基站列表
    .base-station-list {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin: 0px 6px;
      // width: 100%;
      flex-wrap: wrap;
    }
  }
}
</style>